<template>
<div class="social-icons">
    <div class="circle">
        <a :href="whatsapp" title="Telegram" target="_blank">
            <img :src="require('@/assets/whatsapp.svg')" alt="whatsApp">
        </a>
    </div>
    <div class="circle">
        <a :href="telegram" title="Telegram" target="_blank">
            <img :src="require('@/assets/telegram.svg')" alt="telegram">
        </a>
    </div>
</div>
</template>

<script>
export default {
    name: "SocialIcons",
    data: () => ({
      whatsapp: 'https://api.whatsapp.com/send?phone=79877439777',
      telegram: 'https://t.me/LikeBGru'
    })
}
</script>

<style lang="scss">
.social-icons {
    position: fixed;
    display: flex;
    bottom: 40px;
    right: 40px;
    z-index: 1;
    .circle {
        background: #fff;
        width: 70px;
        height: 70px;
        border-radius: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:first-child {
            margin-right: 20px;
        }
    }
}
</style>
<template>
    <section class="banks">
        <h2 class="title text-center">Банки</h2>
        <div class="min-title text-center">
            Закроют все потребности клиента
        </div>
        <!-- <div class="banks__block">
            <b-row>
                <b-col cols="12" lg="6" xl="3" v-for="b in banks" :key="b.id">
                    <div class="banks__item">
                        <div class="banks__item-badge" v-if="b.exclusive"><span>⚡ Эксклюзив!</span></div>
                        <div class="image-block">
                            <img :src="require(`@/assets/${b.img_link}`)" :alt="b.title">
                        </div>
                        <div class="title text-center banks__item-title-desktop">
                            {{ b.title }}
                        </div>
                        <div class="title text-center banks__item-title-mobile">
                            {{ b.short_title || b.title }}
                        </div>
                        <div class="banks__item-info">
                            <div class="info-item">
                                <span class="info-item__name">Сумма</span>
                                <span class="info-item__arg">{{ b.summ }}</span>
                            </div>
                            <hr>
                            <div class="info-item">
                                <span class="info-item__name">Срок</span>
                                <span class="info-item__arg">{{ b.term }}</span>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div> -->
        <div class="banks__block">
            <div class="swiper">
                <div class="swiper-wrapper">
                    <div v-for="b in banks" :key="b.id" class="swiper-slide">
                        <div class="banks__item">
                            <div class="banks__item-badge" v-if="b.exclusive"><span>⚡ Эксклюзив!</span></div>
                            <div class="image-block">
                                <img :src="require(`@/assets/${b.img_link}`)" :alt="b.title">
                            </div>
                            <div class="title text-center">
                                {{ b.title }}
                            </div>
                            <div class="banks__item-info">
                                <div class="info-item">
                                    <span class="info-item__name">Сумма</span>
                                    <span class="info-item__arg">{{ b.summ }}</span>
                                </div>
                                <hr>
                                <div class="info-item">
                                    <span class="info-item__name">Срок</span>
                                    <span class="info-item__arg">{{ b.term }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="swiper-pagination"></div>
            </div>
            <div class="swiper-button swiper-button-prev">
                <img :src="require(`@/assets/chevron-left.svg`)" />
            </div>
            <div class="swiper-button swiper-button-next">
                <img :src="require(`@/assets/chevron-right.svg`)" />
            </div>
        </div>
    </section>
</template>

<script>
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

export default {
    name: "Banks",
    data() {
        return {
            banks: [
                {
                    id: 1, img_link: 'souz.svg', title: 'Ингосстрах Банк', summ: 'до 30 млн.', term: 'до 1915 дней'
                },
                {
                    id: 2, img_link: 'psb.svg', title: 'Промсвязьбанк', summ: 'до 100 млн.', term: 'до 1140 дней'
                },
                {
                    id: 3, img_link: 'mts.svg', title: 'МТС Банк', summ: 'до 50 млн.', term: 'до 1095 дней'
                },
                {
                    id: 4, img_link: 'tsb.svg', title: 'Трансстройбанк', summ: 'до 7 млн.', term: 'до 2280 дней', exclusive: true
                },
                {
                    id: 5, img_link: 'gtb.svg', title: 'Газтрансбанк', summ: 'до 5 млн.', term: 'до 1920 дней', exclusive: true
                },
                {
                    id: 6, img_link: 'sber.svg', title: 'Сбербанк', summ: 'до 100 млн.', term: 'до 1860 дней'
                },
            ],
            swiper: null
        }
    },
    mounted() {
        this.swiper = new Swiper('.swiper', {
            slidesPerView: 1.2,
            spaceBetween: 24,
            modules: [Navigation, Pagination, Autoplay],
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            autoplay: {
                delay: 5000
            },
            slidesOffsetBefore: 25,
            slidesOffsetAfter: 25,
            slidesPerGroup: 1,
            breakpoints: {
                768: {
                    slidesPerView: 2.5,
                    slidesPerGroup: 2,
                    slidesOffsetBefore: 50,
                    slidesOffsetAfter: 50,
                },
                1081: {
                    slidesPerView: 3.5,
                    slidesPerGroup: 3,
                },
                1281: {
                    slidesPerView: 4.4,
                    slidesPerGroup: 4,
                },
                1367: {
                    slidesPerView: 4.3,
                    slidesPerGroup: 4,
                },
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
}
</script>

<style lang="scss" scoped>
.banks__block{
    position: relative;
}
.swiper{
    mask-image: linear-gradient(to left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5%, rgba(255,255,255,1) 95%,rgba(0,0,0,0) 100%);
    margin-left: 0px;
    margin-right: 0px;
}
.swiper-button {
    position: absolute;
    top: 50%;
    margin-top: -22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 50px;
    height: 50px;
    z-index: 10;
    background-color: rgba(255,255,255,1);
    border-radius: 50%;
    box-shadow: 0px 2px 25px rgba(0,0,0,0.1);

    &>img {
        width: 50%;
    }

    &-disabled {
        opacity: .0;
        cursor: auto;
        pointer-events: none;
    }

    &-prev {
        left: 0;
        margin-left: 0px;
    }

    &-next {
        right: 0;
        margin-right: 0px;
    }
}

.swiper-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;

    ::v-deep &-bullet {
        width: 10px;
        height: 10px;
        display: block;
        background: white;
        border-radius: 50%;
        cursor: pointer;

        &-active {
            background-color: #08A651;
            cursor: default;
        }
    }
}
</style>

<style lang="scss">
.banks {
    margin-top: 100px;

    &__block {
        margin-bottom: 60px;
        margin-top: 60px;
    }

    &__item {
        background: #FFFFFF;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.02);
        border-radius: 15px;
        padding: 30px;
        position: relative;
        margin-bottom: 24px;

        &-badge {
            position: absolute;
            top: 20px;
            right: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 134px;
            font-weight: 400;
            font-size: 15px;
            line-height: 20px;
            background: #F1F4F9;
            border-radius: 20px;
        }

        &-title-desktop {}

        &-title-mobile {
            display: none;
        }

        .image-block {
            height: 120px;
            margin-top: 50px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                max-height: 90px;
                min-height: 80px;
            }
        }

        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            text-align: center;

        }

        &-info {
            margin-top: 50px;

            .info-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &__name {
                    font-weight: 400;
                    font-size: 20px;
                    line-height: 25px;
                    color: #777777;
                }

                &__arg {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    .col-12:last-child {
        .image-block img {
            min-height: 50px;
        }
    }
}

@media (max-width: 1440px) {
    .banks__item {
        &-title-desktop {
            display: none;
        }

        &-title-mobile {
            display: block;
        }
    }
}

@media (max-width: 1280px) {
    .banks {
        .col-xl-3 {
            flex: 0 0 auto;
            width: 50%;
        }

        .min-title {
            max-width: 600px;
            margin: 0 auto;
        }

        &__item {
            &-title-desktop {
                display: block;
            }

            &-title-mobile {
                display: none;
            }
        }
    }
}

@media (max-width: 767px) {
    .banks {
        .col-xl-3 {
            flex: 0 0 auto;
            width: 100%;
        }

        .min-title {
            max-width: 100%;
        }
    }

    .agent {
        margin-top: 80px;
    }
}
</style>

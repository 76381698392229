<template>
    <footer class="footer">
        <div>
            <p>© 2022, LikeBG, ООО&nbsp;«План Б» Все права на визуально-графические изображения, информацию и аналитические материалы, размещенные на&nbsp;сайте принадлежат правообладателю и&nbsp;охраняются законодательством Российской Федерации.</p>

            <p>ООО&nbsp;«План Б» является Оператором Информационной системы LikeBG.</p>

            <p>ООО&nbsp;«План Б» не&nbsp;оказывает финансовые (банковские) услуги. Все финансовые (банковские) услуги в&nbsp;рамках Информационной системы LikeBG&nbsp;оказывают Банки-Партнёры.</p>
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss">
.footer {
    padding: 80px 0;
    p {
        color: #999;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
    }
}
@media(max-width: 768px) {
    .footer {
        padding: 60px 0;
        p {
            font-size: 14px;
            line-height: 18px;
        }
    }
}
</style>
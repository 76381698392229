<template>
    <div class="main-page">
        <Banner/>
        <Guarantees/>
        <Banks/>
        <BecomeAnAgent/>
    </div>
</template>

<script>
import Banner from "@/components/main/Banner"
import Guarantees from "@/components/main/Guarantees"
import BecomeAnAgent from "@/components/main/BecomeAnAgent"
import Banks from "@/components/main/Banks"

export default {
    name: 'Home',
    components: {
        BecomeAnAgent,
        Guarantees,
        Banner,
        Banks
    }
}
</script>

<template>
    <div class="wrapper">
        <div class="fon">
        <div class="rectangle rectangle-top" />
        <div class="rectangle rectangle-bottom" />
        </div>
        <Navbar />
        <b-container class="main-container">
            <router-view/>
            <Footer />
        </b-container>
        <SocialIcons />
        <ConfidentialWidget />
    </div>
</template>

<script>
import Navbar from "@/layout/components/Navbar"
import Footer from "@/layout/components/Footer";
import SocialIcons from "@/layout/components/SocialIcons";
import ConfidentialWidget from "@/layout/components/ConfidentialWidget";

export default {
    name: "default",
    components: {
        ConfidentialWidget,
        SocialIcons,
        Navbar,
        Footer
    }
}
</script>

<style lang="scss">
.wrapper {
    width: 100%;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    position: relative;
    overflow-x: hidden;
    .fon {
        z-index: -2;
        background: #EDF1F7;
        position: absolute;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .main-container {
        padding-top: 20px;
    }

    .main-title {
        font-weight: 600;
        font-size: 70px;
        line-height: 80px;
        margin: 30px 0;
    }

    .title {
        font-weight: 600;
        font-size: 50px;
        line-height: 60px;

        span.bordered-text {
            font-weight: 400;
            border: 1px solid #000;
            border-radius: 20px;
            padding: 6px 24px 14px;
        }
    }

    .min-title {
        font-weight: 400;
        font-size: 25px;
        line-height: 35px;
    }

    .btn-green {
        width: 300px;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background: #08A651;
        box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.02);
        border-radius: 5px;
        border: 0;
        color:#fff;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.02em;
        text-transform: uppercase;

        &:hover {
            background: #157347;
            color: #fff;
        }
    }

    .btn-green-circle {
        background: #08A651;
        border: 0;
        border-radius: 100px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #157347
        }
    }
    hr {
        margin: 20px 0;
    }
}
    .rectangle {
        position: absolute;
        border-radius: 1000px;
        width: 1000px;
        height: 1000px;
        z-index: -1;
        opacity: 0.7;

        &.rectangle-top {
            background: linear-gradient(243.18deg, #FFFFFF 17.93%, rgba(255, 255, 255, 0) 89.93%, rgba(255, 255, 255, 0) 89.94%);
            right: -500px;
            top: 970px;
            transform: matrix(-1, 0, 0, 1, 0, 0);
        }

        &.rectangle-bottom {
            left: -400px;
            top: 1800px;
            background: linear-gradient(166.84deg, #FFFFFF 20.63%, rgba(255, 255, 255, 0) 55.81%, rgba(255, 255, 255, 0) 55.81%);
        }
    }

@media (min-width: 1441px) {
    .wrapper .container {
        max-width: 1400px;
    }
}
@media (max-width: 1440px) {
    .wrapper {
        .container {
            max-width: 1270px;
        }
        .main-title {
            font-size: 50px;
            line-height: 60px;
        }
        .title {
            font-size: 40px;
            line-height: 1.5;
        }
    }
}
@media (max-width: 1280px) {
    .wrapper .container {
        max-width: 1070px;
    }
}
@media (max-width: 1080px) {
    .wrapper {
        .container {
            max-width: 760px;
        }
        .title {
            font-size: 30px;
        }
    }
    .rectangle {
        display: none;
    }
}
@media (max-width: 767px) {
    .wrapper {
        .container {
            max-width: 540px;
        }
        .main-title {
            font-size: 35px;
            line-height: 40px;
        }
        .title {
            font-size: 24px;
             span.bordered-text {
                 padding: 6px 14px 10px;
             }
         }
        .min-title {
            font-size: 22px;
            line-height: 25px;
        }
        .btn-green {
            width: 100%;
        }
    }
}
@media (max-width: 400px) {
    .wrapper {
        .title {
            line-height: 40px;
        }
    }
}
</style>
<template>
    <div v-if="isShowModal" class="confidential-widget-wrapper">
        <div class="widget-modal">
            <div class="widget-card">
                <div class="card__body">
                    <!-- <div class="icon">Х</div> -->
                    <a class="confidential-widget__close-btn" @click="getStorage">✕</a>
                    Этот сайт использует cookies для улучшения взаимодействия с пользователями. Но так как Роскомнадзор считает cookie
                    персональными данными, то продолжая находиться на этом сайте, вы даете свое согласие и на работу с cookie в рамках
                    <a href="/agent/documents" target="_blank" class="confidential-widget__link">Политики обработки персональных данных ООО "План Б"</a>.
                    <!-- Мы используем «cookie». Продолжая пользоваться сайтом, вы соглашаетесь с
                    <a href="/agent/documents" target="_blank" class="green-link">
                        Политики конфиденциальности
                    </a> -->
                    <div class="mt-3">
                        <button type="button" class="btn btn-outline" @click="getStorage">
                            <span class="text-nowrap">Хорошо, хорошо</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

const STORAGE_KEY = 'cookieInfo';
const LANDING_STORAGE_KEY = 'confidential_approved';
export default {
    name: "ConfidentialWidget",
    data() {
        return {
            isShowModal: true
        }
    },
    mounted() {
        if(localStorage.getItem(LANDING_STORAGE_KEY)){
            this.cookieApproved();
        }
        if (localStorage.getItem(STORAGE_KEY)) {
            this.isShowModal = false
        }
    },
    methods: {
        getStorage() {
            const date = new Date()
            localStorage.setItem(LANDING_STORAGE_KEY, String(date));
            this.cookieApproved();
            this.isShowModal = false
        },
        cookieApproved(){
            localStorage.setItem(STORAGE_KEY, 'true');
        }
    }
}
</script>

<style lang="scss">
.confidential-widget-wrapper {
    font-family: 'Roboto', 'Inter', sans-serif;
    .confidential-widget__link{
        color: white;
    }
    .confidential-widget__close-btn{
        position: absolute;
        top: 0.7rem;
        right: 0.7rem;
        text-decoration: none;
        cursor: pointer;
        color: #6d6d6d;
        color: white;
    }
    .widget-modal {
        position: fixed;
        padding: 2rem;
        bottom: 0px;
        // width: 350px;
        margin: 0px auto;
        left: 0px;
        z-index: 10;
        font-size: 12px;

        .widget-card {
            max-width: 27em;
            font-size: 0.9rem;
            padding: 2rem;
            padding-top: 2rem;
            color: rgb(23, 43, 77);
            background: #598BFF;
            position: relative;
            color: white;
            box-shadow: 0 2px 12px 0 rgb(0 0 0 / 20%);
            border-radius: 8px;
            line-height: 1.5rem;

            // .green-link {
            //     color: rgb(8, 166, 82);
            //     text-decoration: none;

            //     &:hover {
            //         color: #07954a;
            //         text-decoration: underline;
            //     }
            // }

            .icon {
                color: rgb(8, 166, 82);
                border: 2px solid rgb(8, 166, 82);
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                right: 24px;
                border-radius: 20px;
                height: 14px;
                width: 14px;

            }

            .btn-outline {
                font-size: 14px;
                height: 40px;
                border: 1px solid white;
                color: white;
                outline: none;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 12px;
                font-weight: bold;
                padding: 1.125rem 1rem;
                font-family: Arial, 'Inter', sans-serif;

                &:hover {
                    background-color: white;
                    color: #576179;
                }
            }
        }

    }
}
</style>
<template>
    <section class="guarantees">
        <h2 class="title text-center">Все виды гарантий — <span class="bordered-text">в&nbsp;одном&nbsp;окне</span></h2>
        <div class="guarantees__block">
            <b-row>
                <b-col lg="12" xl="4" v-for="(g, index) in guarantees" :key="g.id">
                    <div class="guarantees__item">
                        <div class="circle">
                            <img :src="require(`@/assets/${g.img_link}`)" :alt="g.title">
                        </div>
                        <div v-if="index===2" id="banks"></div>
                        <div class="title" v-html="g.title"/>
                    </div>
                </b-col>
            </b-row>
        </div>
    </section>
</template>

<script>
export default {
    name: "Guarantees",
    data() {
        return {
            guarantees: [
                {
                    id: 1, img_link: 'g1.svg', title: '44-ФЗ, 223-ФЗ, 615-ПП, <br>коммерция, без размещения, закрытые'
                },
                {
                    id: 2,
                    img_link: 'g2.svg',
                    title: 'Участие, исполнение, гарантийные обязательства, гарантии с авансом'
                },
                {
                    id: 3, img_link: 'g3.svg', title: 'Сумма до 100 млн. рублей<br>Срок до 2280 дней'
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.guarantees {
    padding-top: 100px;

    &__block {
        margin-top: 80px;
        background: #f1f4f9;
        padding-top: 40px;
        padding-bottom: 40px;
        border-radius: 15px;
    }

    .col-xl-4 {
        border-right: 1px solid #ddd;
    }

    .col-xl-4:last-child {
        border-right: 0;
    }

    &__item {
        padding: 30px 20px 20px;

        .circle {
            width: 80px;
            height: 80px;
            background: #FCFBFB;
            box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
            border-radius: 100px;
            margin: 0 auto 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 35px;
            }
        }

        .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 30px;
            text-align: center;
        }
    }
}
@media (max-width: 1440px) {
    .guarantees__item .title {
        padding: 0 30px;
    }
}
@media (max-width: 1280px) {
    .guarantees__block {
        padding: 40px;

        .col-xl-4 {
            flex: 0 0 auto;
            width: 100%;
            border-right: 0;
            padding: 20px 0;
            border-bottom: 1px solid #ddd;
        }

        .col-xl-4:last-child {
            border-bottom: 0;
        }
    }
    .title {
        line-height: 50px;
        max-width: 500px;
        margin: 0 auto;
    }
}
@media (max-width: 768px) {
    .guarantees {
        &__block .col-xl-4 {
            padding: 0;
        }

        &__item .title {
            padding: 0 30px;
        }
    }
}
</style>
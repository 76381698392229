<template>
  <div id="app">
    <default />
  </div>
</template>

<style lang="scss">

</style>
<script>
import Default from "@/layout/default";
export default {
    components: {Default}
}
</script>